// app/routes/login.tsx

import { Form, redirect, useActionData, useLoaderData } from '@remix-run/react';
import { useEffect, useState, useTransition } from 'react';

export async function loader({ request }) {
  const env = process.env.NODE_ENV;

  return {
    env,
  };
}

export let action = async ({ request }) => {
  let formData = new URLSearchParams(await request.text());
  let email = formData.get('email');
  let password = formData.get('password');

  const apiUrl = import.meta.env.VITE_API_URL;
  let response = await fetch(`${apiUrl}/users/sign_in`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user: { email, password },
    }),
  });

  if (response.ok) {
    let data = await response.json();
    if (data.jwt) {
      // Create a new response with the JWT set in a cookie
      let cookieHeader = `jwt=${data.jwt}; HttpOnly; Path=/; SameSite=Lax`;

      return redirect('/', {
        headers: {
          'Set-Cookie': cookieHeader,
        },
      });
    }
  }

  return response.json();
};

export default function Login() {
  const { env } = useLoaderData();
  console.log('env:', env);
  let actionData = useActionData();
  let transition = useTransition();

  const apiUrl = import.meta.env.VITE_API_URL;
  const url = `${apiUrl}/users/auth/google_oauth2`;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f0f0f0',
      }}
    >
      <div
        style={{
          padding: '2rem',
          borderRadius: '8px',
          backgroundColor: '#fff',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          width: '400px',
        }}
      >
        <h2 style={{ marginBottom: '1rem' }}>Sign In</h2>
        <Form
          method="post"
          style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          <label
            style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
          >
            Email:{' '}
            <input
              type="email"
              name="email"
              style={{
                padding: '0.5rem',
                borderRadius: '4px',
                border: '1px solid #ccc',
              }}
            />
          </label>
          <label
            style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
          >
            Password:{' '}
            <input
              type="password"
              name="password"
              style={{
                padding: '0.5rem',
                borderRadius: '4px',
                border: '1px solid #ccc',
              }}
            />
          </label>
          <button
            type="submit"
            id="login"
            style={{
              padding: '0.75rem',
              borderRadius: '4px',
              border: 'none',
              backgroundColor: '#007bff',
              color: '#fff',
              cursor: 'pointer',
            }}
          >
            {transition.submission ? 'Logging in...' : 'Log in'}
          </button>
          {actionData && <p style={{ color: 'red' }}>{actionData.error}</p>}
        </Form>
        <a
          href={url}
          style={{
            marginTop: '1rem',
            display: 'inline-block',
            color: '#007bff',
            textDecoration: 'none',
          }}
        >
          Sign in with Google
        </a>
      </div>
    </div>
  );
}
